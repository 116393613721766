





























































.tabs {
    $color: #ff1932;
    .tab {
        font-size: .3rem;
        padding: .1rem;
        margin: .2rem;
        cursor: pointer;
        display: inline-block;
        letter-spacing: .02rem;
        color: #222;
		@media (max-width: 767px) {
		font-size: 0.4rem;
		 
		 }
		  
        &:first-child {
            margin-left: 0;
        }

        &.selected {
            color: $color;
            border-bottom: $color solid 2px;
        }
    }

}
